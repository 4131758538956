define("discourse/plugins/discourse-zoom/discourse/initializers/composer-toolbar-webinar-button", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-zoom/discourse/components/modal/webinar-picker"], function (_exports, _pluginApi, _webinarPicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeWebinarButton(api) {
    const composerService = api.container.lookup("service:composer");
    const modal = api.container.lookup("service:modal");
    api.addComposerToolbarPopupMenuOption({
      condition: composer => {
        return composer.model && composer.model.creatingTopic;
      },
      icon: "video",
      label: "zoom.webinar_picker.button",
      action: () => {
        modal.show(_webinarPicker.default, {
          model: {
            topic: composerService.model,
            setWebinar: value => composerService.model.set("webinar", value),
            setZoomId: value => composerService.model.set("zoomId", value),
            setWebinarTitle: value => composerService.model.set("zoomWebinarTitle", value),
            setWebinarStartDate: value => composerService.model.set("zoomWebinarStartDate", value)
          }
        });
      }
    });
  }
  var _default = _exports.default = {
    name: "composer-toolbar-webinar-button",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      const currentUser = container.lookup("service:current-user");
      if (siteSettings.zoom_enabled && currentUser) {
        (0, _pluginApi.withPluginApi)("1.13.0", initializeWebinarButton);
      }
    }
  };
});