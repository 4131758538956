define("discourse/plugins/discourse-zoom/discourse/initializers/admin-menu-webinar-button", ["exports", "@ember/application", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/plugin-api", "I18n", "discourse/plugins/discourse-zoom/discourse/components/modal/webinar-picker"], function (_exports, _application, _ajax, _ajaxError, _pluginApi, _I18n, _webinarPicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "discourse-zoom";
  function initialize(api) {
    api.addTopicAdminMenuButton(topic => {
      const canManageTopic = api.getCurrentUser()?.canManageTopic;
      if (!topic.isPrivateMessage && canManageTopic) {
        return {
          icon: "shield-alt",
          label: topic.get("webinar") ? "zoom.remove_webinar" : "zoom.add_webinar",
          action: () => {
            if (topic.get("webinar")) {
              const dialog = api.container.lookup("service:dialog");
              const topicController = api.container.lookup("controller:topic");
              removeWebinar(topic, dialog, topicController);
            } else {
              const modal = api.container.lookup("service:modal");
              showWebinarModal(topic, modal);
            }
          }
        };
      }
    });
    api.modifyClass("component:topic-timeline", {
      pluginId: PLUGIN_ID,
      removeWebinar() {
        const owner = (0, _application.getOwner)(this);
        const dialog = owner.lookup("service:dialog");
        const topicController = owner.lookup("controller:topic");
        removeWebinar(this.topic, dialog, topicController);
      },
      addWebinar() {
        const modal = (0, _application.getOwner)(this).lookup("service:modal");
        showWebinarModal(this.topic, modal);
      }
    });
  }
  var _default = _exports.default = {
    name: "admin-menu-webinar-button",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initialize);
    }
  };
  function showWebinarModal(topic, modal) {
    topic.set("addToTopic", true);
    modal.show(_webinarPicker.default, {
      model: {
        topic,
        setWebinar: value => topic.set("webinar", value),
        setZoomId: value => topic.set("zoomId", value),
        setWebinarTitle: value => topic.set("zoomWebinarTitle", value),
        setWebinarStartDate: value => topic.set("zoomWebinarStartDate", value)
      }
    });
  }
  function removeWebinar(topic, dialog, topicController) {
    dialog.confirm({
      message: _I18n.default.t("zoom.confirm_remove"),
      didConfirm: () => {
        (0, _ajax.ajax)(`/zoom/webinars/${topic.webinar.id}`, {
          type: "DELETE"
        }).then(() => {
          topic.set("webinar", null);
          topicController.set("editingTopic", false);
          document.querySelector("body").classList.remove("has-webinar");
          topic.postStream.posts[0].rebake();
        }).catch(_ajaxError.popupAjaxError);
      }
    });
  }
});