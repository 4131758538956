define("discourse/plugins/discourse-zoom/discourse/lib/webinar-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formattedSchedule = formattedSchedule;
  function formattedSchedule(start, end) {
    const startMoment = moment(start);
    const endMoment = moment(end);
    return `${startMoment.format("LT")} - ${endMoment.format("LT")}, ${startMoment.format("Do MMMM, Y")}`;
  }
});