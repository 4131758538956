define("discourse/plugins/discourse-zoom/discourse/components/webinar-option-row", ["exports", "@ember/component", "discourse-common/utils/decorators", "discourse/plugins/discourse-zoom/discourse/lib/webinar-helpers"], function (_exports, _component, _decorators, _webinarHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    model: null,
    onSelect: null,
    init() {
      this._super(...arguments);
      this.onSelect = this.onSelect || (() => {});
    },
    schedule(model) {
      return (0, _webinarHelpers.formattedSchedule)(model.start_time, moment(model.start_time).add(model.duration, "m").toDate());
    },
    actions: {
      selectWebinar() {
        this.onSelect();
      }
    }
  }, [["method", "schedule", [(0, _decorators.default)("model")]]]));
});