define("discourse/plugins/discourse-zoom/discourse/templates/connectors/user-activity-bottom/webinars-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route="userActivity.webinars">
    {{i18n "zoom.webinars_title"}}
  </LinkTo>
  */
  {
    "id": "nL6CMkxp",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"userActivity.webinars\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,1],[\"zoom.webinars_title\"],null]],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-zoom/discourse/templates/connectors/user-activity-bottom/webinars-list.hbs",
    "isStrictMode": false
  });
});