define("discourse/plugins/discourse-zoom/discourse/templates/components/remove-webinar-from-composer", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if model.zoomId}}
    <div class="composer-webinar">
      <span class="webinar-label">Webinar - </span>
  
      <span class="webinar-title">
        {{model.zoomWebinarTitle}}
      </span>
  
      <DButton
        class="cancel no-text"
        @action={{action "removeWebinar"}}
        @icon="times"
        @title="zoom.remove"
      />
    </div>
  {{/if}}
  */
  {
    "id": "ItL/5WnH",
    "block": "[[[41,[30,0,[\"model\",\"zoomId\"]],[[[1,\"  \"],[10,0],[14,0,\"composer-webinar\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"webinar-label\"],[12],[1,\"Webinar - \"],[13],[1,\"\\n\\n    \"],[10,1],[14,0,\"webinar-title\"],[12],[1,\"\\n      \"],[1,[30,0,[\"model\",\"zoomWebinarTitle\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,1],[[24,0,\"cancel no-text\"]],[[\"@action\",\"@icon\",\"@title\"],[[28,[37,2],[[30,0],\"removeWebinar\"],null],\"times\",\"zoom.remove\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-zoom/discourse/templates/components/remove-webinar-from-composer.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/discourse-zoom/discourse/templates/components/remove-webinar-from-composer.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-zoom/discourse/templates/components/remove-webinar-from-composer.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});