define("discourse/plugins/discourse-zoom/discourse/components/remove-webinar-from-composer", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    model: null,
    actions: {
      removeWebinar() {
        this.model.set("zoomId", null);
      }
    }
  });
});